import React, { useState, useEffect, useRef } from 'react';

const Participant = ({ participant, mirrorVideo }) => {
  const [videoTracks, setVideoTracks] = useState([]);

  const videoRef = useRef();

  useEffect(() => {
    console.log(participant);
    setVideoTracks(Array.from(participant.videoTracks.values()));

    const trackSubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } 
    };

    const trackUnsubscribed = track => {
      if (track.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } 
    };

    const trackStarted = track => {
      if (track.kind === 'video') {
        debugger;
      } 
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackStarted', trackStarted)

    return () => {
      setVideoTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTracks.length > 1){
      debugger;
    }

    if (videoTrack && videoTrack.track) {
      videoTrack.track.attach(videoRef.current);
      return () => {
        videoTrack.track.detach();
      };
    }
  }, [videoTracks]);

  let styles = {}
  if (mirrorVideo){
    styles.transform = `scale(-1, 1)` 
  }

  return (
    <div className="participant">
      <video ref={videoRef} style={styles} autoPlay={true} />
    </div>
  );
};

export default Participant;
