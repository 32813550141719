import React, { useState, useEffect } from 'react';
import Video, { LocalVideoTrack } from 'twilio-video';
import Participant from './Participant';

const Room = ({ roomName, token, handleLogout }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };

    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };

    Video.connect(token, {
      name: roomName,
      video: {
        // mode: 'collaboration',
        // maxSubscriptionBitrate: 2400000,
        // dominantSpeakerPriority: 'high',
        // maxTracks: 5,
        renderDimensions: {
          high: {width: 1080, height: 720},
          standard: {width: 1080, height: 720},
          // standard: {width: 640, height: 480},
          // low: {width: 320, height: 240}
        }
      },
      audio: false,
      preferredVideoCodecs:['VP8','H264']
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const handleShareScreen = async function (){
    const stream = await navigator.mediaDevices.getDisplayMedia();
    const screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
    room.localParticipant.publishTrack(screenTrack);    
  };

  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} />
  ));

  return (
    <React.Fragment>
      <button onClick={handleShareScreen}>Share Screen</button>
      <div className="room">
        
        {remoteParticipants &&
          <div className="remote-participants">{remoteParticipants}</div>
        }
        {/* <h2>Room: {roomName}</h2> */}
        
        <div className="local-participant">
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              mirrorVideo
            />
          ) : (
            ''
          )}
        </div>
        
      </div>
    </React.Fragment>
  );
};

export default Room;
