import React, { useState, useCallback, useEffect } from 'react';
// import Lobby from './Lobby';
import Room from './Room';

const VideoChat = () => {
  const [roomName] = useState('myRoom');
  const [token, setToken] = useState(null);


  // const handleRoomNameChange = useCallback(event => {
  //   setRoomName(event.target.value);
  // }, []);

  useEffect(
    ()  => {
      fetch(`https://test.dev.cradle.io/api/v1/video/token?&roomName=${roomName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.json())
      .then((data) => {
        setToken(data.token);
        console.log('Success:', data);
      })
    },
    [roomName]
  );

  const handleLogout = useCallback(event => {
    setToken(null);
  }, []);

  let render;
  if (token) {
    render = (
      <Room roomName={roomName} token={token} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <div>
        Connecting...
      </div>
      // <Lobby
      //   roomName={roomName}
      //   handleRoomNameChange={handleRoomNameChange}
      //   // handleSubmit={handleSubmit}
      // />
    );
  }
  return render;
};

export default VideoChat;
